import React from "react"

import Layout from "@src/components/Layout"
import Row from "../../V2/components/Row/Row"
import ImageRow from "../../V2/Features/components/ImageRow/ImageRow"
import Button from "../../../components/Button/Button"
import getAppUrl from "@src/utils/getAppUrl"
import DotBG from "../../V2/components/DotBG/DotBG"
import CalloutWithDemoForm from "../../../components/CalloutWithDemoForm/CalloutWithDemoForm"
import Splash from "../../V2/Features/components/Splash"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import HeaderImage from "@src/icons/marketplaces/b2b_header.png"
import SectionOne from "@src/icons/marketplaces/b2b_section_one.png"
import SectionTwo from "@src/icons/marketplaces/b2b_section_two.png"
import SectionThree from "@src/icons/marketplaces/b2b_section_three.png"
import SectionFour from "@src/icons/marketplaces/b2b_section_four.png"
import OG from "@src/icons/og/OG_B2B.jpg"

import * as styles from "./B2B.module.scss"

const Financial = () => {
  const appUrl = getAppUrl.hook()
  const breakpoints = useBreakpoint()

  return (
    <Layout
      title="A better and faster way to build B2B Marketplace"
      description="Unlock the power of innovation and create your own B2B marketplace without writing a single line of code."
      url="https://canonic.dev/industries/financial-services"
      ogImage={OG}
    >
      <Splash
        title="A better and faster way to build B2B Marketplace"
        subtitle="Unlock the power of innovation and create your own B2B marketplace without writing a single line of code."
        illustration={<img src={HeaderImage} />}
        ctas={[
          <a href={appUrl} target="_blank" rel="noopener noreferrer">
            <Button isPrimary>Try for free</Button>
          </a>,
          <a href={"#get-a-demo"}>
            <Button borderless marginLeft>
              Get a Demo
            </Button>
          </a>,
        ]}
        reverseOnMobile
        className={styles.b2b}
      />
      <Row>
        {breakpoints.mobile ? (
          <DotBG height={"120%"} reverse />
        ) : (
          <DotBG height={"calc(100% + 256px)"} offset={"-120px"} reverse />
        )}
        <div className={styles.b2bSection}>
          <h2>Why choose no-code for your B2B Marketplace?</h2>
          <p>
            <strong>Agility and Speed</strong>: Getting your B2B marketplace up
            and running has never been easier. Your vision can be brought to
            life quickly using no-code tools, testing, and iterating along the
            way. You'll be astounded by how rapidly you can adjust and meet
            market demands when you're not constrained by the restrictions of
            conventional development.
          </p>
          <p>
            <strong>Cost-effectiveness</strong>: Wave goodbye to costly
            development and ongoing maintenance bills. You can scale your B2B
            marketplace, customize it, and construct it at a fraction of the
            cost.
          </p>
          <p>
            <strong>Scalability</strong>: You want your B2B marketplace to
            expand along with your business as it gets recognition. Because
            no-code technologies are designed to scale effortlessly, your
            platform can accommodate growing traffic, transaction volume, and
            user expectations. You may increase functionality and add features
            without having to write any code, so you're always prepared to seize
            new chances.
          </p>
        </div>
      </Row>
      <section>
        <Row className={styles.b2bImageRow}>
          {breakpoints.mobile ? (
            <DotBG height={"150%"} offset={"-3.5%"} reverse />
          ) : (
            <DotBG height={"calc(100% + 256px)"} offset={"-130px"} reverse />
          )}
          <div>
            <h2>How Canonic can be a great fit </h2>
            <p className={styles.b2bImageRowSubtitle}>
              Our goal at Canonic is to empower businesses to build their B2B
              marketplaces easily. With cutting-edge no-code tools, Canonic
              ensures you have all the resources and support you need to build a
              thriving digital ecosystem.
            </p>
            <p>Let’s explore some features:</p>
          </div>
          <ImageRow
            title="Customizable templates for various industries:"
            subtitle="Canonic offers various editable templates specially created for multiple industries to help you get started. These templates serve as the framework for your B2B marketplace by providing exclusive features and functionality to your sector."
            screenshot={SectionOne}
            borderless
            className={styles.retailProcessSources}
          />
          <ImageRow
            title="Seamless integration with popular payment gateways:"
            subtitle="Canonic smoothly connects with well-known payment gateways, enabling your consumers to pay using the techniques of their choice. By providing a seamless payment experience, you enhance trust and convenience for your buyers and sellers, ultimately driving more transactions on your platform."
            screenshot={SectionTwo}
            reverse
            borderless
            className={styles.retailProcessBoost}
          />
          <ImageRow
            title="Robust user management and authentication system:"
            subtitle="With this feature, you have advanced control over user management and authentication within your B2B marketplace. You can create user roles, set permissions, and implement authentication mechanisms to ensure the privacy and security of your platform. This feature helps you maintain a trusted environment, safeguard sensitive information, and manage user access effectively."
            screenshot={SectionThree}
            borderless
            className={styles.retailTailorMade}
          />
          <ImageRow
            title="Powerful analytics and reporting tools:"
            subtitle="This feature provides you with valuable insights into the performance and usage of your B2B marketplace. You can track key metrics, such as user activity, sales data, conversion rates, and more. By leveraging these analytics and reporting tools, you gain actionable information to make informed business decisions, optimize your marketplace, and drive growth."
            screenshot={SectionFour}
            reverse
            borderless
            className={styles.retailTailorMade}
          />
        </Row>
      </section>

      <section>
        <Row className={styles.b2bInfoFold}>
          {breakpoints.mobile ? (
            <DotBG height={"100%"} offset={"5%"} />
          ) : (
            <DotBG height={"calc(100% + 256px)"} offset={"-165px"} reverse />
          )}
          <h2 className={styles.retailCardsTitle}>
            The incredible benefits that Canonic brings to your B2B marketplace:
          </h2>
          <p>
            Rapid development and launch of B2B marketplaces: Cut down
            development time and get your marketplace up and running in record
            time.
          </p>
          <p>
            Reduced development costs and maintenance efforts: Eliminate the
            need for costly developers and ongoing maintenance.
          </p>
          <p>
            Scalable infrastructure to handle growing user base and
            transactions: Seamlessly accommodate your business growth without
            compromising performance.
          </p>
          <p>
            Enhanced user experience for buyers and sellers: Delight your
            customers with a user-friendly platform that fosters seamless
            transactions and fosters trust.
          </p>
        </Row>
      </section>

      <CalloutWithDemoForm originPage="B2B" id="get-a-demo" />
    </Layout>
  )
}

export default Financial
